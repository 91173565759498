.contact {
    padding: 2em 0;
    background: #e3f1ff;
    font-size: 1.2em;
  }

.contact > .container {
    height: fit-content;
    width: 70%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .contact__middle {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: center;
    align-items: center;
  }

  .contact__title{
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .contact_avatar_image img {
    width: 20em; 
    object-fit: contain;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
   }

   .contact__us{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
   }

   .contact_info{
    display: flex;
    flex-direction: column;
   }
  
  .contact h2 {
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact p {
    font-size: 18px;
    color: #666;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  
  .contact__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23%;
  }
  
  .contact__form input,
  .contact__form textarea {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact__form button {
    padding: 10px 20px;
    background: #ff6347;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 0.5em;
  }
  
  .contact__form button:hover {
    background: #e55347;
  }

  .getInTouch{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
  }
  .getInTouch__image{
    width: 3em; 
    height: 3em;
    object-fit: contain;
    padding-right: 0.5em;
  }
  .form__text{
    font-size: 1.2em;
  }
  
  @media (max-width: 1200px) {
  .contact__us{
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .contact__middle {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: center;
    align-items: center;
  }
  .contact__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  }
  
  @media (max-width: 768px) {
    .contact__middle {
      display: flex;
      flex-direction: column-reverse;
      height: fit-content;
      justify-content: center;
      align-items: center;
    }

    .getInTouch{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;
    }
    .getInTouch__image{
      width: 2em; 
      height: 2em;
      object-fit: contain;
      padding-right: 0.5em;
    }
    .form__text{
      font-size: 1.2em;
    }
  }
  