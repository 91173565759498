.services {
  padding: 60px 0;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services .container {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  max-width: 1200px;
  margin: 0 auto;
}
.services__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
}

.services__title {
  font-size: 36px;
  color: #333;
  margin-bottom: 1em;
}

.services__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Ensure 5 columns */
  gap: 1em;
  width: fit-content; /* Adjust the width as needed */
}

.services__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  z-index: 1000;
}


.services__item {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services__item.expanded {
  transform: scale(1.05);
  z-index: 2000;
}

.services__item-header {
  padding: 1em;
  background-color: #e3f1ff;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.services__item-header:hover {
  background-color: #b8d5ff;
}

.services__details {
  padding: 1em;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
}

.services__details p {
  font-size: 1em;
  color: #666;
  line-height: 1.6;
}

.services__image {
  width: 4em;
  height: 4em;
  object-fit: contain;
}

.services__avatar {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 5em;
}

.hidden {
  visibility: hidden;
}


@media (max-width: 1400px) {
  .services .container {
    padding-left: 5em;
    width: 80%;
  }
  .services__avatar {
    left: 10em;
  }
}

@media (max-width: 1200px) {
  .services__list {
    grid-template-columns: repeat(2, 1fr); 
  }
  .services__avatar {
   display: none;
  }
}

@media (max-width: 768px) {
  .services .container {
    padding: 0 ;
  }

  .services__avatar {
    width: 100%;
    margin-top: 1em;
  }
  .services__list {
    grid-template-columns: repeat(2, 1fr); 
  }
  .services__avatar {
   display: none;
  }
}
