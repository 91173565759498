/* General header styles */
.header {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  position: sticky;
}

.header .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 95%;
  height: 1em;
  
}

.logo__img {
  height: auto;
  width: 60%;
  display: flex;
  align-items: center;
}

.header__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  position: relative;
}

.header__nav a {
  color: #000;
  margin: 0 15px;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.15em;
  font-weight: 550;
  transition: color 0.3s ease;
}

.header__nav a:hover {
  color: #2066b0; 
}

.header__toggle {
  display: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 1400px) {
  .header__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    position: relative;
  }
  
  .header__nav a {
    color: #000;
    margin: 0 15px;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    font-weight: 550;
    transition: color 0.3s ease;
  }
  
}

/* @media (max-width: 1100px) {

  .header .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: fit-content;
    height: 1em;
    
  }
  .header__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    position: relative;
  }
  
  .header__nav a {
    color: #000;
    margin: 0 15px;
    width: fit-content;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    font-weight: 550;
    transition: color 0.3s ease;
  }
  
} */

/* Mobile styles */
@media (max-width: 1100px) {
  .header__nav {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background: #fff;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .header__nav.open {
    width: 100%;
    max-height: 300px; /* Adjust height for the menu items */
    opacity: 1;
  }

  .header__nav a {
    margin: 10px 0;
  }

  .header__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* Animation styles */
.header__nav {
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

