@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
}

* {
  box-sizing: border-box;
}
  .content {
    width: 100%;
    overflow-x: hidden;
  }
