.about {
  padding: 30px 0;
  background: #e3f1ff;
  display: flex;
  align-items: center;
}

.about .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: fit-content;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;

}

.about__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 100%;
}

.about__text {
  margin-top: 20px;
  max-width: 600px;
}

.about h2 {
  font-size: 3em;
  color: #333;
  margin-bottom: 20px;
}

.about p {
  font-size: 1em;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.6;
}

.about img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);  
    position: relative;
    right: 6em;
  }

  @media (max-width: 1400px) {
    
    .about .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: fit-content;
      width: 90%;
      margin: 0 auto;
    }

    .about  img {   
      flex: 1;
      width: 100%;
      height: auto;
      border-radius: 50%;
      position: relative;
      right: 2em;
    }
  }
  
  

  @media (max-width: 1100px) {
    .about .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: fit-content;
      width: 100%;
      margin: 0 auto;
    }
    
    .about__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1 1 100%;
    }

    .about__text {
      padding-right: 2em;
      max-width: 600px;
    }
    .about  img {   
      display: none;
    }
  }

  @media (max-width: 768px) {

    .about {
      padding: 30px 0;
      background: #e3f1ff;
      display: flex;
      align-items: center;
    }
    
    .about .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: fit-content;
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
    
    }
    
    .about__text {
      padding-right: 0em;
    }

    .about__content {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      flex: 1 1 100%;
    }
           
    .about  img {   
      display: flex;
      flex: 1;
      width: 100%;
      height: auto;
      border-radius: 50%;
      position: relative;
      left: 0em;
    }
    }
    