.footer {
    background: #333;
    padding: 20px 0;
    color: #fff;
    text-align: center;
  }
  
  .footer p {
    margin: 0;
  }
  