.clientele {
  padding: 50px 0;
  /* background: #e6e6e6; */
  background: #f9f9f9;
}

.clientele > .container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.clientele h2 {
  font-size: 36px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.clientele__carousel {
  width: 110%;
  max-width: 120%;
  display: flex;
  gap: 1em; /* Adds a gap between carousel items */
}
.clientele__carousel .slick-slide {
  padding: 0 0.5em; /* Adjusting padding to add space between items */
  box-sizing: border-box;
}

.clientele__item {
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  margin: 0 0.5em; /* Adds margin to each item */
  box-sizing: border-box;
  border: 5px solid #e3f1ff;
  height: fit-content;
}

.slick-slide {
  display: flex;
  justify-content: center; /* Centers the items within the slide */
  box-sizing: border-box;
}


.clientele__item h3 {
  font-size: 1.7em;
  color: #333;
  margin-bottom: 1.3em;
}

.clientele__item p {
  font-size: 18px;
  color: #666;
}

.slick-prev:before,
.slick-next:before {
  font-size: 24px;
  color: #333;
}

@media (max-width: 1400px) {
  .clientele > .container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    margin: 0 auto;
  }
  .clientele__carousel {
    width: 110%;
    max-width: 150%;
    display: flex;
    gap: 1em; /* Adds a gap between carousel items */
  }
  
}

@media (max-width: 1200px) {

}

@media (max-width: 768px) {
 
}
