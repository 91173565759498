/* .home {
    display: flex;
    align-items: center;
    padding: 2em 0;
  } */

  .home .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; 
  }
  
  .home__intro {
    flex: 1;
  }
  
  .home__intro--text{
    
  }
  
  .home__intro h2 {
    font-size: 2.7em;
    color: #333;
  }
  
  .home__intro p {
    font-size: 1.1em;
    color: #666;
  }
  
  /* .home__image {
    text-align: center;
  } */
  
  .home img {   
    flex: 1;
    width: 83%;
    height: auto;
    border-radius: 50%;
  }


  @media (max-width: 1400px) {

    .home .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin: 0 auto; 
      padding-bottom: 1em;
    }

      
  .home img {   
    flex: 1;
    width: 100%;
    height: auto;
    border-radius: 50%;
  }

  }
  
  

  @media (max-width: 1100px) {

    .home {
    display: flex;
    align-items: center;
    padding: 2em 0;
  }

    .home .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin: 0 auto; 
    }

      
  .home img {   
    flex: 1;
    width: 150%;
    height: auto;
    border-radius: 50%;
  }

  }
  

  @media (max-width: 768px) {

  .home {
    display: flex;
    align-items: center;
    padding: 0em 0;
    text-align: center;
  }

    .home .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      max-width: 1200px;
      margin: 0 auto; 
    }
         
  .home  img {   
    flex: 1;
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  }
  